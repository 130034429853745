<template>
  <div :style="computedStyle" v-show="!isHidden">
  <span class="registry_fields_label">
    {{ label }}
      <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
  </span>
    <el-form-item
      :prop="name"
      :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
    ]">
    <el-date-picker
            :size="size"
            v-bind:value="value"
            v-on:input="$emit('input', $event)"
            class="datetime_field_width"
            type="datetime"
            :name="name"
            :placeholder="placeholder || $locale.main.placeholder.datetime_field"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="{ firstDayOfWeek: 1 }"
            format="dd.MM.yyyy HH:mm:ss"
            :readonly="readonly">
    </el-date-picker>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
export default {
  name: 'datetime_field',
  mixins: [mixin, registryMixin],
  props: {
    name: {
      type: String,
      frozen: true
    },
    label: {
      description: 'Название',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>
  .datetime_field_width.el-date-editor.el-input{
    width: 100%;
  }
</style>
